import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { getCurrentContracts, getAccountStatus, getCreditLimit, getUserFlags } from '@src/api';

import {MODERATION_STATUSES} from '@src/constants';

import { ProfileActionTypes } from './types';
import {
  getContractsFailure,
  getContractsSuccess,
  getAccountStatusSuccess,
  getAccountStatusFailure,
  getCreditLineSuccess,
  getCreditLineFailure,
  getUserFlagsSuccess,
  getUserFlagsFailure
} from './actions';

function* handleGetUserFlags() {
  try {
    const { result, error } = yield call(getUserFlags);

    if (error?.response?.data) {
      throw new Error(error?.response?.data?.error_description);
    }
    else {
      yield put(getUserFlagsSuccess(result.data))
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(getUserFlagsFailure(err.message));
    } else {
      yield put(getUserFlagsFailure('An unknown error occured.'));
    }
  }
}

function* handleGetContracts() {
  try {
    const { result, error } = yield call(getCurrentContracts);

    if (error?.response?.data) {
      throw new Error(error?.response?.data?.error_description);
    }
    else {
      yield put(getContractsSuccess(result.data))
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(getContractsFailure(err.message));
    } else {
      yield put(getContractsFailure('An unknown error occured.'));
    }
  }
}

function* handleGetAccountStatus() {
  try {
    const { result, error } = yield call(getAccountStatus);
    const { result: contractsRes } = yield call(getCurrentContracts);

    const status = contractsRes?.data.length > 0 ? {result: MODERATION_STATUSES.VERIFIED} : result.data;

    if (error) {
      yield put(getAccountStatusSuccess({result: 'true'}))
      throw new Error(error?.response?.data?.error_description);
    }
    else {
      yield put(getAccountStatusSuccess(status))
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(getAccountStatusFailure(err.message));
    } else {
      yield put(getAccountStatusFailure('An unknown error occured.'));
    }
  }
}

function* handleGetCreditLine() {
  try {
    const { result, error } = yield call(getCreditLimit);

    if (error?.response?.data) {
      throw new Error(error?.response?.data?.error_description);
    }
    else {
      yield put(getCreditLineSuccess(result.data))
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(getCreditLineFailure(err.message));
    } else {
      yield put(getCreditLineFailure('An unknown error occured.'));
    }
  }
}

function* watchProfile() {
  yield takeLatest(ProfileActionTypes.GET_USER_FLAGS_REQUEST, handleGetUserFlags);
  yield takeLatest(ProfileActionTypes.GET_CONTRACTS_REQUEST, handleGetContracts);
  yield takeLatest(ProfileActionTypes.GET_CREDIT_LINE_REQUEST, handleGetCreditLine);
  yield takeLatest(ProfileActionTypes.GET_ACCOUNT_STATUS_REQUEST, handleGetAccountStatus);
}
/**
 * @desc saga init, forks in effects, other sagas
 */
export default function* profileSaga() {
  yield all([fork(watchProfile)]);
}
